<template>
  <br />

  <table class="mx-auto my-2" style="width: 100%">
    <tr>
      <th style="width: 44%">NFT Power</th>
      <th style="width: 44%">
        <span title="current power">
          {{ Math.round(nft.power / 100) / 100 }}⚡
        </span>
      </th>
    </tr>

    <tr>
      <td>
        <span title="current level"><b>Current Level</b></span>
      </td>
      <td>
        <span title="current level">{{ nft.level }}</span>
      </td>
    </tr>

    <tr>
      <td>
        <span title="progress within level"><b>Progress in Level</b></span>
      </td>
      <td>
        <span title="progress within level"
          >{{ nft.pctInLevelX100 / 100 }}%
        </span>
      </td>
    </tr>

    <tr>
      <td>
        <span title="value"><b>NFT Value</b> </span>
      </td>
      <td>
        <span title="value"
          ><b>{{ nft.sacrificePrice }} EXP </b></span
        >
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["nft"],
};
</script>


<style scoped>
span,
tr,
td,
th,
b {
  color: #bf0b3a;
}
</style>