<template>
  <p v-if="isDogechain">
    Please note: It is recommended to use the dogmoney rpc. Other rpc servers
    may not be able to load the most recent data.
  </p>
  <p>
    Please open the console of the browser to copy the arrays. The first array
    contains the current power of each NFT of this collection. The second array
    contains the level of each NFT of this collection.
  </p>
  <p>
    The arrays are indexed on the NFT ID which means the power for NFT #1, #2,
    and #3 are in the 0th, 1st, and 2nd position in the array.
  </p>
  <p>
    The arrays contain integers only. You get the staking power by dividing each
    number of the first array by 10,000.
  </p>
</template>

<script>
export default {
  props: ["isDogechain"],
};
</script>
