<template>
  <br />

  <div v-if="nft.level >= maxLevel">Max. level reached! 👏</div>

  <span title="current power">
    Power
    {{ Math.round(nft.power / 100) / 100 }}⚡
  </span>
  <span v-if="nft.level < maxLevel" title="current level"
    >Level {{ nft.level }}
  </span>
  <button
    :disabled="disable"
    @click="$emit('sacrifice')"
    class="btn btn-danger"
    style="font-size: 14px"
  >
    Sacrifice for
    {{ nft.sacrificePrice }} EXP
  </button>
</template>

<script>
export default {
  props: ["nft", "disable", "maxLevel"],
  emits: ["sacrifice"],
};
</script>
