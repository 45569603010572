<template>
  <br />

  <template v-if="nft.nftLevelAfterUpgrade <= maxLevel">
    <h6 class="mt-1 mb-0">Upgrade</h6>
    <table class="mx-auto my-2" style="width: 100%">
      <tr>
        <th style="width: 25%">Power</th>
        <th style="width: 37%">
          <span title="current power">
            {{ Math.round(nft.power / 100) / 100 }}⚡
          </span>
        </th>
        <th style="width: 37%">
          <span title="power after upgrade" v-if="nft.nftPowerAfterUpgrade > 0">
            to
            {{ Math.round(nft.nftPowerAfterUpgrade / 100) / 100 }}⚡
          </span>
        </th>
      </tr>

      <tr>
        <td>From</td>
        <td>
          <span title="current level">level {{ nft.level }} </span>
        </td>
        <td>
          @
          <span title="progress within level"
            >{{ nft.pctInLevelX100 / 100 }}%
          </span>
        </td>
      </tr>
      <tr>
        <td>to</td>
        <td>
          <span title="level after upgrade">
            level
            <input
              type="number"
              @change="$emit('calculateExpTokensForUpgradeAndSetPctTo0')"
              :min="nft.level"
              :max="maxLevel"
              :value="nftLevelAfterUpgrade"
              @input="$emit('update:nftLevelAfterUpgrade', $event.target.value)"
              style="width: 3em"
            />
            <!-- {{ nft.nftLevelAfterUpgrade }} -->
          </span>
        </td>
        <td>
          @
          <span title="progress within level after upgrade">
            <!-- <input
                                type="number"
                                @change="calculateExpTokensForUpgrade(tokenId)"
                                min="1"
                                max="9999"
                                step="2000"
                                v-model="
                                  nft.pctInLevelX100AfterUpgrade
                                "
                                style="width: 4em"
                              />
                              = -->
            {{ nft.pctInLevelX100AfterUpgrade / 100 }}%
          </span>
        </td>
      </tr>
      <tr>
        <td>with</td>
        <td>
          <span
            title="Select how many EXP tokens you want to spend on upgrading your NFT's staking power"
          >
            <input
              type="number"
              @change="$emit('checkExpTokensForUpgrade')"
              min="1"
              :max="2 ** 24 - 1"
              :value="expTokensForUpgrade"
              @input="$emit('update:expTokensForUpgrade', $event.target.value)"
              :disabled="nft.level >= maxLevel"
              style="width: 5em"
          /></span>
        </td>
        <td>EXP</td>
      </tr>
    </table>

    <div>
      <!-- Replace above div with cutout.txt if you want to change back the inputs to multiple range sliders -->

      <template v-if="nft.expTokensUntilNextLevel === nft.expTokensForUpgrade">
        <button
          @click="$emit('upgrade')"
          :disabled="
            nft.level >= maxLevel ||
            stakersExpBalance < expTokensForUpgrade ||
            noAllowanceForLeveling
          "
          class="btn btn-primary"
        >
          🔥{{ nft.expTokensUntilNextLevel }} EXP to reach next level!
        </button>
      </template>
      <template v-else>
        <button
          @click="$emit('upgrade')"
          class="btn btn-primary"
          :disabled="
            nft.level >= maxLevel ||
            stakersExpBalance < expTokensForUpgrade ||
            noAllowanceForLeveling
          "
          style="font-size: 14px"
        >
          Upgrade for
          {{ nft.expTokensForUpgrade }} EXP
        </button>
      </template>
    </div>
  </template>
  <template v-if="nft.level >= maxLevel">
    <table class="mx-auto my-2" style="width: 100%">
      <tr>
        <th style="width: 25%">Power</th>
        <th style="width: 37%">
          <span title="current power">
            {{ Math.round(nft.power / 100) / 100 }}⚡
          </span>
        </th>
      </tr>

      <tr>
        <td>Level</td>
        <td>
          <span title="Final level reached">{{ nft.level }} </span>
        </td>
      </tr>
    </table>
    Max. level reached! 👏
  </template>
</template>

<script>
export default {
  props: [
    "nft",
    "nftLevelAfterUpgrade",
    "expTokensForUpgrade",
    "noAllowanceForLeveling",
    "stakersExpBalance",
    "maxLevel",
  ],
  emits: [
    "update:nftLevelAfterUpgrade",
    "update:expTokensForUpgrade",
    "upgrade",
    "checkExpTokensForUpgrade",
    "calculateExpTokensForUpgradeAndSetPctTo0",
  ],
};
</script>
