<template>
  <br />

  <span title="current power">
    Power
    {{ Math.round(nft.power / 100) / 100 }}⚡
  </span>

  <span title="current level"> 🏆{{ nft.level }} </span>
  <div class="d-flex justify-content-center mt-3">
    <button
      v-if="nft.staked"
      @click="$emit('unstake')"
      :disabled="disable"
      class="btn btn-warning"
    >
      Unstake
    </button>
    <button
      v-else
      @click="$emit('stake')"
      :disabled="disable"
      class="btn btn-primary"
    >
      Stake
    </button>
  </div>

  <div v-if="nft.level >= maxLevel">Max. level reached! 👏</div>
</template>

<script>
export default {
  props: ["nft", "disable", "maxLevel"],
  emits: ["stake", "unstake"],
};
</script>
