<template>
  <div class="row">
    <div class="col-xxl-6">
      <div class="card top-bid">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col align-items-center">
              <h4>NFT Power Upgrades</h4>
              <div>
                <span>
                  You can increase the staking power of your NFTs using power
                  upgrades.
                </span>
                <span>
                  For power upgrades you need EXP tokens which you can buy in
                  the
                  <a href="./exp">EXP Shop</a>.
                </span>
                <span>
                  Each update increases the staking power of your NFT
                  permanently.
                </span>
                <span>
                  When your NFT reaches the next level, the staking power within
                  the previous level receives a permanent 42% bonus power
                  increase.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-6">
      <div class="card top-bid">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col align-items-center">
              <h4 class="mx-auto">
                Staking Power Upgrade Costs Overview for {{ projectname }}
              </h4>
              <span>
                <strong>
                  {{
                    Math.floor(
                      (expFor1LvlUpgrdTo[1] * discountExpTokensX1k) / 1000
                    )
                  }}
                  EXP
                </strong>
                upgrade cost to <strong>level 1</strong>
              </span>
              <div v-for="(level, index) in levelToUpgradeTo" :key="index">
                <span>
                  <strong>
                    {{
                      Math.floor(
                        (expTokensIncPerLevel[index] * discountExpTokensX1k) /
                          1000
                      )
                    }}
                    EXP
                  </strong>
                  upgrade cost <b>increase per level</b> below
                  <strong> level {{ Math.min(level, maxLevel) }} </strong>
                </span>
              </div>
              <strong>Max Level: {{ maxLevel }}</strong>
              <div>
                Staking power bonus for finishing a level:
                <strong>42.86%</strong>
              </div>
              <h4 class="mt-4">Additional staking power bonuses</h4>
              <ul>
                <li>
                  for reaching level 25:
                  <strong>5%</strong>
                </li>
                <li>
                  for reaching level 50:
                  <strong>10%</strong>
                </li>
                <li>
                  for reaching level 75:
                  <strong>15%</strong>
                </li>
                <li>
                  for reaching level 100:
                  <strong>20%</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "projectname",
    "expFor1LvlUpgrdTo",
    "discountExpTokensX1k",
    "levelToUpgradeTo",
    "expTokensIncPerLevel",
    "maxLevel",
  ],
};
</script>