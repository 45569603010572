<template>
  <div class="row">
    <div class="card top-bid">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col align-items-center">
            <span>
              Before you can {{ doSomething }}, you need to approve the
              {{ contract }} {{ once }}.
            </span>
            <button @click="$emit('approve')" class="btn btn-primary">
              Approve {{ contract }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contract", "doSomething", "once"],
  emits: ["approve"],
};
</script>
