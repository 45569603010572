<template>
  <div id="showMore" class="btn">more</div>
</template>

<script>
export default {
  props: {
    waitTime: {
      type: Number,
      default: 1000,
    },
  },

  emits: ["showMore"],

  data() {
    return {
      waitTimeCorrected: 1000,
      runningInterval: 0,

      preloadPages: 7,
    };
  },

  methods: {
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top;

      const isVisible = elemTop < this.preloadPages * window.innerHeight;
      return isVisible;
    },

    checkIfShowMoreIsNecessary() {
      const that = this;
      that.runningInterval = window.setInterval(function () {
        // console.log("checking");

        const scrolledTo = document.querySelector("#showMore");

        if (scrolledTo && that.isScrolledIntoView(scrolledTo)) {
          that.$emit("showMore");
        }
      }, this.waitTimeCorrected);
    },

    // loadMoreNftsIfScrolledDown() {
    // performance wasn't good. That's why I switched to an interval for checking

    // loads more NFTs when we scroll down

    // // works well if we don't have two halves side by side
    // window.onscroll = () => {
    //   that.checkIfShowMoreIsNecessary();
    // };
    // // works well if on computer using scroll wheel
    // window.onwheel = () => {
    //   that.checkIfShowMoreIsNecessary();
    // };
    // // works well if on computer using keyboard to scroll
    // window.onkeyup = () => {
    //   that.checkIfShowMoreIsNecessary();
    // };
    // // works if on computer using touchscreen to scroll
    // window.onmousemove = () => {
    //   that.checkIfShowMoreIsNecessary();
    // };
    // },
  },

  mounted() {
    // load more NFTs (preload-1) sites in advance
    // >= 3 NFTs next to each other
    if (window.innerWidth < 530) {
      // only 1 column of NFTs
      this.preloadPages = 17;
    } else if (window.innerWidth < 800) {
      // 2 NFTs next to each other
      this.preloadPages = 10;
    } else {
      this.preloadPages = 7;
    }
    this.waitTimeCorrected = Number(this.waitTime);
    if (this.waitTimeCorrected < 100 || Number.isNaN(this.waitTimeCorrected)) {
      this.waitTimeCorrected = 100;
    }
    console.log(this.waitTimeCorrected);

    this.checkIfShowMoreIsNecessary();
  },

  beforeUnmount() {
    // console.log("clearing");
    window.clearInterval(this.runningInterval);
  },
};
</script>


